@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.image {
  overflow: hidden;
  img {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
}

.fillContainer {
  // 101% accounts for a Browser rendering bug
  height: 101%;

  img {
    width: 100%;
    height: 100%;
    // max-width: none;
    object-fit: cover;
  }
}

.fitContainer {
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    // max-width: none;
    object-fit: contain;
  }
}

.centerAlign {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.hoverFilter {
  filter: grayscale(1);
  transition: filter 0.2s var(--transiton-timing-ease);
  &:hover {
    filter: grayscale(0);
  }
}

// Fix flicker issue on safari
.borderRadius {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.borderRadius--s {
  border-radius: var(--border-radius-small);
}

.borderRadius--m {
  border-radius: var(--border-radius);
}

.borderRadius--l {
  border-radius: var(--border-radius-large);
}
